<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <Spinner :spin="spin"/>
        <section class="header activity_questions">
            <a class="icon iconButton third" @click.prevent="$router.push('/service')">
                <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
            </a>
            <span class="fL nowrap">{{ title }}</span>
            <div class="icon iconButton"></div>
        </section>
        <!-- Main -->
        <section class="main activity_questions">
            <div v-if="campaigns.length > 0">
                <a
                    class="news flexH width margin between"
                    @click.prevent="toDetail(campaign.campaignId)"
                    v-for="(campaign, index) in campaigns"
                    :key="'campaign'+index"
                >
                    <div class="info flexV padding margin height">
                        <span class="fL title">{{campaign.campaignName}}</span>
                        <span class="fS gray">{{campaign.departmentName}}</span>
                        <!-- <span class="fS double">{{campaign.campaignDesc}}</span> -->
                        <span class="fS double" v-html="campaign.campaignDesc"></span>
                        <span class="fS gray">{{tsToDate(campaign.campaignStartTime)}} - {{tsToDate(campaign.campaignEndTime)}}</span>
                    </div>
                    <div class="reward flexV padding margin center middle">
                        <!-- 即將發完 -->
                        <div class="label soon" v-if="showRewordInsufficient(campaign)">
                            <span class="fM single orange">即將發完</span>
                        </div>
                        <div class="img"><img src="@/assets/icon/point.svg"></div>
                        <!-- <span class="fM">100點</span> -->
                        <!-- 已發完 -->
                        <div class="label absolute gray" v-if="checkRewardCompleted(campaign)">
                            <span class="fM single gray center">獎勵<br/>已發完</span>
                        </div>
                        <!-- 已作答 -->
                        <div class="label absolute" v-else-if="campaign.hasCompleted">
                            <div class="icon">
                                <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon>
                            </div>
                            <span class="fM single">已參與</span>
                        </div>
                    </div>
                </a>
            </div>
            <div v-else>
                <!-- 無內容 -->
                <div class="listEmpty flexV width center middle">
                    <div class="icon secondary"><font-awesome-icon icon="fa-solid fa-dice-d6" size="10x"></font-awesome-icon></div>
                    <!-- <span class="fXXL">本日沒有問答</span> -->
                    <span class="fL gray">目前沒有{{ title }}！</span>
                    <!-- <span class="fL gray">感謝參與回答，您已經回答過囉！</span> -->
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';

export default {
    components: {
        Spinner
    },
    name: "",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            campaigns: [],
            spin: false,
        };
    },
    mounted() {
        this.getCampaigns();
    },
    computed: {
        ...mapState(["user"]),
        campaignType() {
          return this.$route.path.includes('on-site-activities') ? 'check-in' : 'qna';
        },
        title() {
            return this.campaignType === 'check-in'  ? '實體活動報到': '市政問答'
        },
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        getDummyCampaigns() {
            let result = {
                uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
                memberId: 208,
                campaigns: [
                    {
                        campaignId: "M_RdROw7eY5B",
                        campaignName: "測試幸運輪盤任務",
                        campaignDesc: "輪盤抽獎。Yay!!",
                        campaignStatus: "approved",
                        campaignPrototype: "minigame",
                        campaignStartTime: 1652284800,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652322816,
                        missions: [
                            {
                                missionId: 44,
                                missionName: "幸運輪盤（小遊戲任務）",
                                missionDesc: "As title",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["38"]
                                        },
                                        status: "completed",
                                        completedAt: 1652341991
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_r5PDKD0m58",
                        campaignName: "PrePOB測試任務：抽獎小遊戲",
                        campaignDesc: "As title",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652112000,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652167000,
                        missions: [
                            {
                                missionId: 36,
                                missionName: "抽獎小遊戲",
                                missionDesc: "好禮大方送",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["31"]
                                        },
                                        status: "completed",
                                        completedAt: 1652170978
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_xd4wXAwvkj",
                        campaignName: "PrePOB測試任務：推薦",
                        campaignDesc:
                            "To recommend or not to recommend, that is the question.",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652025600,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652070487,
                        missions: [
                            {
                                missionId: 31,
                                missionName: "測試推薦任務 之 推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1652092171
                                    },
                                    {
                                        status: "completed",
                                        completedAt: 1652092477
                                    }
                                ]
                            },
                            {
                                missionId: 32,
                                missionName: "推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項"
                            },
                            {
                                missionId: 34,
                                missionName: "被推薦人關卡",
                                missionDesc:
                                    "接受他人推薦連結並成功註冊者可以領取獎項"
                            },
                            {
                                missionId: 35,
                                missionName: "註冊關卡",
                                missionDesc: "成功註冊者可以領取獎項"
                            }
                        ]
                    },
                    {
                        campaignId: "M_p5O76qR854",
                        campaignName: "註冊任務",
                        campaignDesc: "成功註冊桃園市民卡即可獲得100點",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1651132800,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1651129632,
                        missions: [
                            {
                                missionId: 22,
                                missionName: "會員註冊任務",
                                missionDesc:
                                    "只要成功註冊會員即可獲得，每人限領一次",
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652253394
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_qkwQ9ygK5L",
                        campaignName: "2022問答任務debug測試",
                        campaignDesc: "答對有獎，限量三名",
                        campaignStatus: "approved",
                        campaignPrototype: "test",
                        campaignStartTime: 1650816000,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1650862201,
                        missions: [
                            {
                                missionId: 16,
                                missionName: "問答任務 測試",
                                missionDesc: "有獎問答 測試",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1651387810
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: result});
                }, 1000);
            });
            return promise;
        },
        getCampaignsAPI() {
            const publisher = this.$route.meta.publisher;
            const param = publisher ? `&publisherType=${publisher}` : '';
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true${param}`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            this.spin = true;
            return (
                this.getCampaignsAPI()
                    // this.getDummyCampaigns()
                    .then((res) => {
                        this.campaigns = res.data.campaigns.filter(
                            campaign =>
                                campaign.campaignPrototype === this.campaignType
                                && campaign.campaignVisibility == 'public'
                        );
                        this.sortCampaigns();
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
                    .finally(() => this.spin = false)
            );
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns);
        },
        toDetail(campaignId) {
            const path = this.campaignType === 'check-in'
                            ? `/service/check-in/${campaignId}`
                            : `/service/qa/${campaignId}`;
            this.$router.push({path});
        },
        // calCompletedMissions(campaign) {
        //     if (
        //         campaign &&
        //         campaign.missions &&
        //         campaign.missions.length > 0 &&
        //         campaign.missions.some((m) => m.participation)
        //     ) {
        //         return campaign.missions.filter((m) => {
        //             if (
        //                 m.participation &&
        //                 m.participation.length > 0 &&
        //                 m.participation[0].status == "completed"
        //             ) {
        //                 return true;
        //             }
        //             return false;
        //         }).length;
        //     }
        //     return 0;
        // },
        // calMissions(campaign) {
        //     if (
        //         campaign &&
        //         campaign.missions &&
        //         campaign.missions.length >= 0
        //     ) {
        //         return campaign.missions.length;
        //     }
        //     return 0;
        // },
        // checkCampaignCompleted(campaign) {
        //     return (
        //         this.calCompletedMissions(campaign) ==
        //         this.calMissions(campaign)
        //     );
        // },
        checkRewardCompleted(campaign) {
            // 先用名稱去擋有沒有預算，未來應該會用campaignBudget存在與否判斷
            return (campaign.campaignBudget && campaign.campaignBudget?.availablePoints <= 0);
        },
        showRewordInsufficient(campaign) {
            // const {campaignBudget: {availablePoints, usedPoints}, hasCompleted} = campaign;
            const campaignBudget = campaign.campaignBudget || undefined;
            const availablePoints = campaignBudget?.availablePoints;
            const usedPoints = campaignBudget?.usedPoints;
            const hasCompleted = campaign.hasCompleted;
            return ( 
                campaignBudget && 
                !hasCompleted && 
                availablePoints > 0 &&
                availablePoints <= (availablePoints + usedPoints) * 0.05
            );
        },
    }
};
</script>
